import React from 'react'
import './About.scss'

export default function About() {
	return (
		<div>
			<div className='about-us px-3'>
				<div className='container'>
					<h1>Our story</h1>
					<div className='row'>
						<div className='col-md-6 col-sm-12 text-black'>
							<p>
								As the only intelligent beings on earth, we have the ability to
								influence the course of our future, our environment and every
								living creatures on earth with great magnitude.
							</p>
							<p>
								As such, we have an obligation to safeguard all inhabitants of
								this planet. The preservation of the identity of these
								inhabitants should be given moral support in order to sustain
								their immediate and future rights for a safe and sustainable
								existence.
							</p>
						</div>
						<div className='col-md-6 col-sm-12'>
							<p>
								​The wealth of humans and their engenuity should be at the
								forefront of stabilizing and protecting the environment and the
								wildlife, which in turn will benefit us in the long term.
							</p>
							<p>
								The NGO, Save the Life International, is dedicated to ensuring
								we have a sustainable future by combating climate change and
								thus protecting not only our way of life, but all living things
								on our shared planet.
							</p>
						</div>
					</div>
					<div className='feature-video embed-responsive embed-responsive-16by9 my-5 mx-auto'>
						<iframe
							className='embed-responsive-item'
							src='https://www.youtube.com/embed/WEzKpTaf2xY'
						/>
					</div>
				</div>
			</div>
			<div className='help-us px-3'>
				<div className='container help-us-content text-white'>
					<h1>How can you help?</h1>
					<p>
						With your support, you have the opportunity to make the impact and
						change thousands of lives. It’s through your generosity that we
						create such powerful change.
					</p>
					<div className='p-5'>
						<div className='row text-center'>
							<div className='col-md-4 col-sm-12 py-3'>
								<i className='my-2 fas fa-piggy-bank fa-2x' />
								<h3>Donation</h3>
								<p>
									Even the smallest donation can help the children in need by
									providing food, shelter and clothes.
								</p>
							</div>
							<div className='col-md-4 col-sm-12 py-3'>
								<i className='my-2 fas fa-graduation-cap fa-2x' />
								<h3>Scholarship</h3>
								<p>
									Our scholarship program is designed to assist children in need
									of financial aid to further their education.
								</p>
							</div>
							<div className='col-md-4 col-sm-12 py-3'>
								<i className='my-2 fas fa-child fa-2x' />
								<h3>Save a life</h3>
								<p>
									The money you donate today will forever change the lives of
									many children and help a growing community. 
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
