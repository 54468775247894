module.exports = {
	email: 'admin@savethelife.co.uk',
	links: [
		{
			name: 'Home',
			url: '/',
		},
		{
			name: 'About',
			url: '/about',
		},
		{
			name: 'Projects',
			url: '/projects',
		},
		{
			name: 'Donate',
			url: '/donate',
		},
		// {
		// 	name: 'Contact',
		// 	url: '/contact',
		// }
	],
};
