import React from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom';
import { links } from '../../../utils/config';

export default function Footer() {
	const year = new Date().getFullYear();
	return (
		<div className='footer'>
			<div className='site-details'>
				<p>&#169; {year} Save the Life International</p>
			</div>
			<div className='nav-footer'>
				<h5>Links</h5>
				<ul>
					{links.map(({ url, name }, key) => (
						<li key={key}>
							<Link to={url}>{name}</Link>
						</li>
					))}
				</ul>
			</div>
			<div className='links'>
				<h5>Media</h5>
				<a href='mailto:admin@savethelife.co.uk'>
					<i className='fas fa-envelope' />
				</a>
				<a href='https://instagram.com/savethelifeinternational'>
					<i className='fab fa-instagram'></i>
				</a>
				<a href='https://www.youtube.com/channel/UChcl8OHkM6RUBWhbbEKRSvQ/videos'>
					<i className='fab fa-youtube' />
				</a>
			</div>
		</div>
	);
}
