import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './ProjectPage.scss';
import { fetchEntries } from '../../../utils/contentfulPosts';
import placeholder from '../../../assets/placeholderLogo.png';
import report from '../../../assets/reports/2020-2021.pdf';

export default function ProjectPage() {
	const [projects, setProjects] = useState([]);

	useEffect(() => {
		async function fetchContent() {
			const res = await fetchEntries();
			const posts = await res.map((post) => {
				const item = {
					...post.fields,
					id: post.sys.id,
					createdAt: post.sys.createdAt,
					images: post.fields.cloudinaryImages,
				};
				delete item.cloudinaryImages;
				return item;
			});
			setProjects(posts);
		}
		fetchContent();
	}, []);

	return (
		<div className='projects px-3'>
			<div className='container donate-content'>
				<h1>Our work</h1>
				<h2>
					The path out of poverty begins when the next generation can access quality healthcare and a great education.
				</h2>
				<p>
					In developing countries, we focus on improving people’s health and wellbeing, helping individuals lift
					themselves out of hunger and extreme poverty. we seek to ensure that all people—especially those with the
					fewest resources—can access the opportunities they need to succeed in school and life.
				</p>
				<div className='my-5'>
					<h2>Financial reports</h2>
					<a className='report' href={report} target='_blank'>
						2020-2021
					</a>
				</div>
				<div>
					<h2 className='my-3'>Projects</h2>
					<div className='row'>
						{projects.map((project) => {
							const { id, title, description, images, video } = project;
							return (
								<div className='col-sm-12 col-lg-4 col-md-6 py-3' key={id}>
									<Link
										to={{
											pathname: `/project/${id}`,
											state: {
												title: title,
												description: description,
												images: images,
												video: video,
											},
										}}
									>
										<div className='card'>
											<img className='card-img-top' src={images ? images[0].url : placeholder} alt='project image' />
											<div className='card-body'>
												<p className='text-center'>{title}</p>
											</div>
										</div>
									</Link>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
}
