import React, { useState, useEffect } from 'react';
import PhotoGallery from '../PhotoGallery';
import './Project.scss';
import { fetchEntry } from '../../../utils/contentfulPosts';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export default function Project(props) {
	const { title, description, video, images } = props.location.state;
	const id = props.match.params.id;

	return (
		<div className='project container px-3'>
			<h1 className='heading'>{title}</h1>
			{description && <div>{documentToReactComponents(description)}</div>}
			<h2>Short documentary</h2>
			<div className='feature-video embed-responsive embed-responsive-16by9 my-5'>
				<iframe
					className='embed-responsive-item'
					src={video}
					frameBorder='0'
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
					allowFullScreen
				/>
			</div>
			{images && (
				<div className='my-5'>
					<h2>Gallery</h2>
					<div className='gallery'>
						{images.map((image, i) => (
							<img key={i} src={image.original_url} />
						))}
					</div>
				</div>
			)}
		</div>
	);
}
