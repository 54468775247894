import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import './Donate.scss';

export default function Donate() {
	const [state, handleSubmit] = useForm('xdoyedwd');
	return (
		<div className='donate px-3'>
			<div className='donate-content container'>
				<h1>Your support can help many lives</h1>
				<p>
					You have the opportunity to impact the lives of individuals and families. It is through your generosity that
					we can create such powerful change.
				</p>
				<p>
					We are currently accepting bank transfers, swift payments, cheques and postal order. Please find all
					information below:
				</p>
				<div className='donate-content-container'>
					<div className='donation-details mt-5'>
						<h2>Donation details</h2>
						<div className='row'>
							<div className='py-2 col-md-4 col-sm-12'>
								<h3 className='h4'>Bank transfer</h3>
								<p>Name: Save the Life International</p>
								<p>Account number: 23366189</p>
								<p>Sort code: 20-92-63</p>
								<p>Barclays Bank PLC</p>
							</div>
							<div className='py-2 col-md-4 col-sm-12'>
								<h3 className='h4'>SWIFT / BIC transfer</h3>
								<p>SWIFT/BIC: BUKBUGB</p>
								<p>IBAN:GB69 BUKB</p>
								<p>2092 6323 3661 89</p>
							</div>
							<div className='py-2 col-md-4 col-sm-12'>
								<h3 className='h4'>Cheque / Postal order</h3>
								<p>Name: Save the Life International</p>
								<p>Charity number: 1176683</p>
								<p>Address: 69 Girton Avenue, LONDON, NW9 9UE</p>
							</div>
						</div>
					</div>
					<div className='contact-form mt-5'>
						{state.succeeded ? (
							<p>Thank you for sending us a message, we will get back to you as soon as possible.</p>
						) : (
							<>
								<h2>Contact Us</h2>
								<form onSubmit={handleSubmit} autoComplete={false} autoCapitalize>
									<div className='mb-4'>
										<label htmlFor='name'>Full name</label>
										<input id='name' type='text' name='full_name' placeholder='E.g. Jon Doe' required />
									</div>
									<div className='mb-4'>
										<label htmlFor='email'>Email Address</label>
										<input id='email' type='email' name='email' placeholder='E.g. jon.doe@example.com' required />
									</div>
									<div className='mb-4'>
										<label htmlFor='message'>Message</label>
										<textarea id='message' name='message' placeholder='Please enter a message' required />
									</div>
									<div className='btn-container mt-4'>
										<button type='reset' disabled={state.submitting}>
											Reset
										</button>
										<button type='submit' disabled={state.submitting}>
											Submit
										</button>
									</div>
								</form>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
