const space = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID;
const accessToken = process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN;

const client = require('contentful').createClient({
	space: space,
	accessToken: accessToken,
});

export async function fetchEntries() {
	const entries = await client.getEntries({ content_type: 'saveTheLifePosts' });
	if (entries.items) return entries.items;
	console.log(`Error getting Entries for ${contentType.name}.`);
}

export async function fetchEntry(name) {
	const entry = await client.getEntries({
		content_type: 'saveTheLifePosts',
		'fields.slug': name,
	});
	if (entry.items) return entry.items;
	console.log(`Error getting Entries for ${contentType.name}.`);
}

export default { fetchEntries, fetchEntry };
