import React from 'react'
import { Link } from 'react-router-dom'
import './Home.scss'
import logo from '../../../assets/logo-3.png'

export default function Home() {
	return (
		<div>
			<div className='hero'>
				<div className='m-5 text-center'>
					<img className='logo mx-auto d-block' src={logo} alt='logo' />
					<h1>Save the Life International</h1>
					<p className='bio'>
						Working within our guidelines together with other humanitarian and
						environmental stakeholders to protect and safeguard lives, habitats
						and the environment to achieve our goals.
					</p>
					<Link to='/donate'>
						<button className='donation'>Donate</button>
					</Link>
				</div>
			</div>
		</div>
	)
}
