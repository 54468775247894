import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Navbar.scss'

export default function Navbar() {
	const [navActive, setNavActive] = useState(false)
	return (
		<div>
			<nav className={`nav ${navActive ? 'active' : ''}`}>
				<div
					className={`hamburger ${navActive ? 'toggle' : ''}`}
					onClick={() => {
						setNavActive(!navActive)
					}}
				>
					<div className='top-bar'></div>
					<div className='middle-bar'></div>
					<div className='bottom-bar'></div>
				</div>
				<ul className={`nav-ul ${navActive ? 'active' : ''}`}>
					<li
						onClick={() => {
							navActive && setNavActive(!navActive)
						}}
					>
						<Link to='/'>Home</Link>
					</li>
					<li
						onClick={() => {
							navActive && setNavActive(!navActive)
						}}
					>
						<Link to='/about'>About Us</Link>
					</li>
					<li
						onClick={() => {
							navActive && setNavActive(!navActive)
						}}
					>
						<Link to='/projects'>Projects</Link>
					</li>
					<li
						onClick={() => {
							navActive && setNavActive(!navActive)
						}}
					>
						<Link to='/donate'>Donate</Link>
					</li>
				</ul>
			</nav>
		</div>
	)
}
