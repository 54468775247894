import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import ProjectPage from './components/ProjectPage';
import Project from './components/Project';
import Donate from './components/Donate';
import Footer from './components/Footer';

export default function App() {
	return (
		<Router>
			<Navbar />
			<Switch>
				<Route path='/' exact component={Home} />
				<Route path='/about' exact component={About} />
				<Route path='/projects' exact component={ProjectPage} />
				<Route path='/project/:id' exact component={Project} />
				<Route path='/donate' exact component={Donate} />
			</Switch>
			<Footer />
		</Router>
	);
}
